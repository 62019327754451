import React from 'react';
import { BrowserRouter, HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import moment from 'moment-timezone';
import { ConfigProvider, message } from 'antd';
import { router } from 'router/router'
import { RouterGuard } from 'router/authRouter'
message.config({ top: 120, maxCount: 1 });
moment.locale('zh-cn');



const getRouter = () => {
    return (
        <HashRouter>
            <Switch>
                <RouterGuard />
                <Redirect to="/" />
            </Switch>
        </HashRouter>
    )
};

export default getRouter;
{/* {
                    router.map((route, key) => {
                        return (
                            <Route
                                key={key}
                                path={route.path}
                                exact={route.exact}
                                render={props => (
                                    <route.component {...props} routes={route.routes} />
                                )}
                            />
                        )
                    })
} */}