import React from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import { router } from 'router/router'
import userMobx from 'store/User/user'
export function RouterGuard() {
    let location = useLocation();
    // 拿到路径
    let { pathname } = location;
    // 拿到当前路由
    let thisRoute = router.find((el) => el['path'] == pathname);
    if (userMobx.isLogined && thisRoute.path == '/login') return <Redirect to="/" />

    //未登录
    if (!thisRoute.auth) {
        return <Route path={pathname} component={thisRoute['component']} exact />
    } else {
        //根据不同的管理员分配过滤不同的权限树
        if (thisRoute.type !== 'admin' && userMobx.userInfo.accountType == 1) {
            return <Route path={pathname} component={thisRoute['component']} exact />
        } else if (thisRoute.type !== 'proxy' && userMobx.userInfo.accountType == 0) {
            return <Route path={pathname} component={thisRoute['component']} exact />
        } else {
            return <Redirect to="/" />
        }
    }
}
