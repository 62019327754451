import Cookies from "js-cookie";

const KeyUserInfo = "uInfo"; //用户Token
const KeyUserToken = "uToken"; //用户Token

// // 设置用户登录的Token
// export function setLoginInfo(loginInfo) {
//     var storage = window.sessionStorage;
//     storage.setItem(KeyUserInfo, JSON.stringify(loginInfo));
//     Cookies.set(KeyUserInfo, JSON.stringify(loginInfo));
// }
// // 获取用户登录的Token
// export function getLoginInfo() {
//     var storage = window.sessionStorage;
//     let res = storage.getItem(KeyUserInfo) || Cookies.get(KeyUserInfo) || "";
//     return JSON.parse(res)
// }

/*********Token********* */
export function setUserToken(loginToken: string): void {
  var storage = window.sessionStorage;
  storage.setItem(KeyUserToken, loginToken);
  Cookies.set(KeyUserToken, loginToken);
}

export function getUserToken() {
  var storage = window.sessionStorage;
  return storage.getItem(KeyUserToken);
}

// 设置用户登录的Token
export function setLoginInfo(loginInfo) {
  var storage = window.sessionStorage;
  storage.setItem(KeyUserInfo, JSON.stringify(loginInfo));
  Cookies.set(KeyUserInfo, JSON.stringify(loginInfo));
}
// 获取用户登录的Token
export function getLoginInfo() {
  var storage = window.sessionStorage;
  let res = storage.getItem(KeyUserInfo) || {};
  if (typeof res == "string") {
    return JSON.parse(res);
  }
}
