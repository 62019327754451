import React from 'react';
import ReactDOM from 'react-dom';
import getRouter from './router';
import { Provider } from 'mobx-react';
import actions from './store/list';
import * as serviceWorker from './serviceWorker';
import './index.less'
const Router = getRouter();

ReactDOM.render(
    <Provider {...actions}>
        <div>{Router}</div>
    </Provider>,
    document.getElementById('root')
)
serviceWorker.unregister();
