import { observable, action } from 'mobx';
import md5 from 'md5'
import { getOrderPage, orderCheck } from 'api/api'
import { setLoginInfo, getLoginInfo } from 'fetch/auth'
import { message } from 'antd';

class AdminOrder {
    @observable proxyOrderList = [];
    @observable formValues = { size: 20, current: 1 } //保存搜索条件

    //查询账户信息
    @action onGetOrderPage = async (values = { size: 20, current: 1 }) => {
        this.formValues = values
        let res = await getOrderPage(values)
        if (res.ret === 'C2') {
            this.proxyOrderList = res.data
        }
    };

    //查询账户信息
    @action onSearchGetOrderPage = async (values) => {
        this.formValues = {
            ...this.formValues,
            ...values
        }
        this.onGetOrderPage(this.formValues)
    };
    // 分页查询
    @action handleBasicTableChange = (pagination): void => {
        const params = {
            // 分页参数
            ...this.formValues,
            current: pagination.current,
            size: pagination.pageSize,
        };
        this.formValues = params;
        // 表格切换查询
        this.onGetOrderPage(params);
    };

    //审核订单
    @action onOrderCheck = async (values, callback) => {
        let res = await orderCheck(values)
        if (res.ret === 'C2') {
            callback()
            message.success("审核成功")
        }
    };
}


export default new AdminOrder();