import axios from "axios";
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { message } from 'antd';
import { toFormData } from 'utils/format'
import { getUserToken, setLoginInfo, getLoginInfo, setUserToken } from 'fetch/auth'
import user from 'store/User/user'
//设置message
interface NewAxiosRequestConfig extends AxiosRequestConfig {
    dataType?: string;
}
interface NewAxiosResponse extends AxiosResponse {
    config: NewAxiosRequestConfig
}

const service = axios.create({
    timeout: 30000,             // 请求超时时间
});

//状态管理
const statusCode = {
    "C2": "成功",
    "C7": "访问拒绝",   //未登陆直接访问拒绝
    "C5": "访问拒绝",   //未登陆直接访问拒绝
    "C37": "请重新登陆",//登陆了，但是登陆过期了
}


// req请求拦截器
service.interceptors.request.use(
    (config: any) => {
        if (config.dataType === 'FormData') { toFormData(config) }
        if (getUserToken()) {
            config.headers.Authorization = getUserToken();
            config.headers['Content-Type'] = "application/json";
        }
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

// res响应拦截器
service.interceptors.response.use(
    (response: NewAxiosResponse) => {
        const { data, headers: { authorization } } = response;
        if (!data) return Promise.reject({
            data: {},
            msg: "后台异常,联系管理员",
            ret: "error"
        });

        if (data.ret == 'C2') {
        } else if (data.ret == 'C7' || data.ret == 'C37' || data.ret == 'C5') {
            //退出登陆
            setLoginInfo(null)
            setUserToken('')
            user.checkUserLogin()
            user.History.push('/')
            message.error(statusCode[data.ret])
        } else {
            message.error(data.msg)
        }
        return data;
    }, error => {
        if (error.response) {
            try {
                let { request: data } = error.response;
                message.error('网络异常');
                return Promise.reject(data);
            } catch (error) {
                return Promise.reject(error);
            }
        }
    }
);

function request(url: string, config: NewAxiosRequestConfig,) {
    return service(url, config)
}

export default request;