import { observable, action } from 'mobx';
import md5 from 'md5'
import { getFundChange, userReset, getPayType, orderPost } from 'api/api'
import { message } from 'antd';
import user from 'store/User/user'
class ProxyUser {
    @observable proxyUserList = []; //帐变列表
    @observable proxyPayType = [];  //支付通道
    @observable formValues: any //保存搜索条件
    //查询账户信息
    @action initUserGet = async (values = { size: 20, current: 1 }) => {
        let res: any = await getFundChange(values)
        if (res.ret === 'C2') {
            this.proxyUserList = res.data
        }
    };

    // 分页查询
    @action handleBasicTableChange = (pagination): void => {
        const params = {
            // 分页参数
            ...this.formValues,
            current: pagination.current,
            size: pagination.pageSize,
        };
        this.formValues = params;
        // 表格切换查询
        this.initUserGet(params);
    };

    //修改密码
    @action userReset = async (values?: any) => {
        values.password = md5(values.password)
        let res = await userReset(values)
        if (res.ret === 'C2') {
            message.success('修改成功')
        }
    };

    //查询支付通道
    @action onGetPayType = async (values?: any) => {
        let res = await getPayType(values)
        if (res.ret === 'C2') {
            this.proxyPayType = res.data.map((item, index) => {
                item.key = item.acctId
                return item
            })
        }
    };

    //提交订单
    @action onOrderPost = async (values?: any) => {
        let res = await orderPost(values)
        if (res.ret === 'C2') {
            user.History.push('/console/proxy/order')
            message.success('提交成功')
        }
    };

}


export default new ProxyUser();