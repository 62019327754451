import { observable, action } from 'mobx';
import md5 from 'md5'
import { appGet, appAdd, appDel, appUpdate, getProduct, postRecharge } from 'api/api'
import { message } from 'antd';

class ProxyApp {
    @observable appList = [];    //app页面List
    @observable productList = [] //续费产品List

    //查询应用信息
    @action initAppGet = async (values?: any) => {
        let res = await appGet(values)
        if (res.ret === 'C2') {
            this.appList = res.data
        }
    };

    //更新应用信息
    @action appUpdate = async (values, callback) => {
        let res = await appUpdate(values)
        if (res.ret === 'C2') {
            callback()
            message.success('更新成功')
            this.initAppGet()
        }
    };

    //删除应用信息
    @action appDel = async (values?: any) => {
        let res = await appDel(values)
        if (res.ret === 'C2') {
            message.success('删除成功')
            this.initAppGet()
        }
    };

    //新增应用信息
    @action appAdd = async (values?: any) => {
        let res = await appAdd(values)
        if (res.ret === 'C2') {
            message.success('新增成功')
            this.initAppGet()
        }
    };

    //获取产品信息
    @action onGetProduct = async (values?: any) => {
        let res = await getProduct(values)
        if (res.ret === 'C2') {
            this.productList = res.data
        }
    };

    //APP续费服务
    @action onPostRecharge = async (values, callback) => {
        let res = await postRecharge(values)
        if (res.ret === 'C2') {
            callback()
            message.success("续费成功")
            this.initAppGet()
        }
    };
}


export default new ProxyApp();