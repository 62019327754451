import userMobx from "store/User/user"; // 登陆

import proxyAppMobx from "store/Proxy/proxyApp";     // 代理应用
import proxyUserMobx from "store/Proxy/proxyUser";   // 代理账户信息
import proxyOrderMobx from "store/Proxy/proxyOrder"; // 代理账户信息

import adminOrderMobx from "store/Admin/adminOrder"; // 管理订单信息
import adminPayMobx from "store/Admin/adminPay";     // 管理支付信息
import adminUserMobx from "store/Admin/adminUser";   // 管理账户信息
import adminAppMobx from "store/Admin/adminApp";   // 管理账户信息


const actions = {
    userMobx,
    proxyAppMobx,
    proxyOrderMobx,
    proxyUserMobx,
    adminOrderMobx,
    adminPayMobx,
    adminUserMobx,
    adminAppMobx
};

export default actions;