import { Select } from 'antd';
const { Option } = Select;

// 处理application/x-www-form-urlencoded格式数据
export function toFormData(config: any) {
    config.transformRequest = [function (data: any) {
        let res = '';
        for (let i in data) res += encodeURIComponent(i) + '=' + encodeURIComponent(data[i]) + '&';
        return res;
    }];
}

// 金额格式化
export function moneyFormat(amount: any) {
    if (!amount) amount = "0";
    amount = amount.toString().replace(/\$|\,/g, '');
    if (isNaN(amount)) {
        amount = "0";
    }
    var sign = (amount === (amount = Math.abs(amount)));
    amount = Math.floor(amount * 100 + 0.50000000001);
    var cents: any = amount % 100;
    amount = Math.floor(amount / 100).toString();
    if (cents < 10) {
        cents = "0" + cents
    }
    for (var i = 0; i < Math.floor((amount.length - (1 + i)) / 3); i++) {
        amount = amount.substring(0, amount.length - (4 * i + 3)) + ',' + amount.substring(amount.length - (4 * i + 3));
    }

    return (((sign) ? '' : '-') + amount + '.' + cents);
}

// uint8Array转对象
export function Utf8ArrayToObj(array: any) {
    var out, i, len, c;
    var char2, char3;
    out = "";
    len = array.length;
    i = 0;
    while (i < len) {
        c = array[i++];
        switch (c >> 4) {
            case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
                // 0xxxxxxx
                out += String.fromCharCode(c);
                break;
            case 12: case 13:
                // 110x xxxx   10xx xxxx
                char2 = array[i++];
                out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
                break;
            case 14:
                // 1110 xxxx  10xx xxxx  10xx xxxx
                char2 = array[i++];
                char3 = array[i++];
                out += String.fromCharCode(((c & 0x0F) << 12) |
                    ((char2 & 0x3F) << 6) |
                    ((char3 & 0x3F) << 0));
                break;
        }
    }
    return JSON.parse(out);
}

//数组去重
export function uniqueArr(array: any) {
    const newArr = array.filter((item: any, index: any, self: any) => self.indexOf(item) === index)
    return newArr
}

//数组去除空
export function bouncer(arr: any) {
    return arr.filter(function (val: any) {
        return !(!val || val === "");
    });
}

// 对象参数转换成拼接字符串   {pageNo:1, pageSize:20} ---> ?pageNo=1&pageSize=20
export function objToStr(obj: any, isEncoding?: any) {
    let str = '?';
    for (let i in obj) {
        if (obj[i] || obj[i] === 0) {
            str += `${i}=${isEncoding ? encodeURI(obj[i]) : obj[i]}&`;
        }
    }
    str = str.slice(0, str.length - 1);
    return str;
}

// 给表格数据添加key
export function listAddKey(res: any) {
    //需优化
    if (!res) return;
    if (res.result) {
        if (Array.isArray(res.result.records)) {
            res.result.records.map((item: any, index: any) => item.key = index);
        } else if (Array.isArray(res.result)) {
            res.result.map((item: any, index: any) => item.key = index);
        } else if (Array.isArray(res.result.columns)) {
            res.result.columns.map((item: any, index: any) => item.key = index + 1);
        } else if (Array.isArray(res.result.faq)) {
            res.result.faq.map((item: any, index: any) => item.key = index + 1);
        } else {
            return
        }
    }
    if (res.data) {
        res.data.map((item: any, index: any) => item.key = index);
    }
    return res
}

// 递归对象数组特定值返回数组(session)
export function recursiveFunction(data: any, prop: any) {
    var arr: any = [];
    const getArr = function (list: any) {
        list.forEach(function (row: any) {
            arr.push(row[prop])
            if (row.children) {
                getArr(row.children)
            }
        })
    }
    getArr(data)
    return arr
}


