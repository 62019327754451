import { observable, action } from 'mobx';
import md5 from 'md5'
import { userGetPage, userUpdate } from 'api/api'
import { message } from 'antd';

class AdminUser {
    @observable userPage = [];
    @observable formValues = { size: 20, current: 1 } //保存搜索条件

    // 获取账户管理页面数据
    @action onUserGetPage = async (values?: any) => {
        let res = await userGetPage(values)
        if (res.ret === 'C2') {
            this.userPage = res.data
        }
    };

    // 获取账户管理页面数据
    @action onSearchGetOrderPage = async (values?) => {
        this.formValues = {
            ...this.formValues,
            ...values
        }
        this.onUserGetPage(this.formValues)
    };

    // 分页查询
    @action handleBasicTableChange = (pagination): void => {
        const params = {
            // 分页参数
            ...this.formValues,
            current: pagination.current,
            size: pagination.pageSize,
        };
        this.formValues = params;
        // 表格切换查询
        this.onSearchGetOrderPage(params);
    };

    // 更新账户
    @action onUserUpdate = async (values: any, callback, isFieldsPassword) => {
        if (isFieldsPassword) values.password = md5(values.password)
        let res = await userUpdate(values)
        if (res.ret === 'C2') {
            message.success("更新成功")
            callback()
            this.onSearchGetOrderPage()
        }
    };
}


export default new AdminUser();