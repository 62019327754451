import { observable, action } from 'mobx';
import md5 from 'md5'
import { getPayType, payAdd, payUpdate, userDel } from 'api/api'
import { setLoginInfo, } from 'fetch/auth'
import { message } from 'antd';

class AdminUser {
    @observable proxyPayList = [];

    //收款方式
    @action onGetPayTypeList = async (values?: any) => {
        let res = await getPayType(values)
        if (res.ret === 'C2') {
            this.proxyPayList = res.data
        }
    };

    //新增收款方式 
    @action onPayAdd = async (values, calllBack) => {
        let res = await payAdd(values)
        if (res.ret === 'C2') {
            message.success('操作成功')
            calllBack()
            this.onGetPayTypeList()
        }
    };

    //更新应用信息
    @action appUpdate = async (values: any, callback) => {
        let res = await payUpdate(values)
        if (res.ret === 'C2') {
            callback()
            message.success('更新成功')
            this.onGetPayTypeList()
        }
    };
    // 删除收款账户
    @action onUserDel = async (values: any) => {
        let res = await userDel(values)
        if (res.ret === 'C2') {
            message.success("删除成功")
            this.onGetPayTypeList()
        }
    };
}


export default new AdminUser();