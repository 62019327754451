import { observable, action } from 'mobx';
import md5 from 'md5'
import { getOrderPage } from 'api/api'
import { message } from 'antd';

class ProxyOrder {
    @observable proxyOrderList = [];
    @observable formValues: any //保存搜索条件
    
    //查询账户信息
    @action onGetOrderPage = async (values = { size: 20, current: 1 }) => {
        let res = await getOrderPage(values)
        if (res.ret === 'C2') {
            this.proxyOrderList = res.data
        }
    };
    // 分页查询
    @action handleBasicTableChange = (pagination): void => {
        const params = {
            // 分页参数
            ...this.formValues,
            current: pagination.current,
            size: pagination.pageSize,
        };
        this.formValues = params;
        // 表格切换查询
        this.onGetOrderPage(params);
    };

}


export default new ProxyOrder();