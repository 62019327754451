// 在这里配置所有的路由组件
import loadable from 'utils/loadable';

const Layout = loadable(() => import('view/Layout'));                                       //4和1主页面
const Help = loadable(() => import('view/Help'));                                           //帮助文档
const SDKDownload = loadable(() => import('view/SDKDownload'));                             //SDK下载
const Login = loadable(() => import('view/User/Login'));                                    //登陆
const Register = loadable(() => import('view/User/Register'));                              //注册
const ProxyAppList = loadable(() => import('view/Console/Proxy/AppList'));                  //应用信息管理
const ProxyOrderList = loadable(() => import('view/Console/Proxy/OrderList'));              //订单信息管理
const ProxyUserList = loadable(() => import('view/Console/Proxy/UserList'));                //账户信息查看
const AdminOrderList = loadable(() => import('view/Console/Admin/OrderList'));              //订单管理
const AdminPayList = loadable(() => import('view/Console/Admin/PayList'));                  //支付管理
const AdminUserList = loadable(() => import('view/Console/Admin/UserList'));                //用户管理
const AppList = loadable(() => import('view/Console/Admin/AppList'));                       //app信息



/**
 * @vdesc 路由配置
 * @vparam  {auth}   判断是否需要登录权限
 * @vparam  {type}   场景分发路由导航权限
*/
export const router: Array<any> = [
    {
        path: "/",
        name: "首页",
        auth: false,
        component: Layout,
        exact: true,
    },
    {
        path: "/scene",
        name: "场景",
        auth: false,
        component: Layout,
    },
    {
        path: "/product",
        name: "产品",
        auth: false,
        component: Layout,
    },
    {
        path: "/price",
        name: "价格",
        auth: false,
        component: Layout,
    },
    {
        path: "/help",
        name: "帮助文档",
        component: Help,
    },
    {
        path: "/sdkDownload",
        name: "SDK下载",
        auth: false,
        component: SDKDownload,
    },
    {
        path: "/login",
        name: "登陆",
        auth: false,
        component: Login,
    },
    {
        path: "/register",
        name: "注册",
        auth: false,
        component: Register,
    },
    {
        path: "/console/admin/order",
        name: "订单管理",
        auth: true,
        type: 'admin',
        component: AdminOrderList
    },
    {
        path: "/console/admin/pay",
        name: "收款账号管理",
        auth: true,
        type: 'admin',
        component: AdminPayList
    },
    {
        path: "/console/admin/user",
        name: "账户管理",
        auth: true,
        type: 'admin',
        component: AdminUserList
    },
    {
        path: "/console/admin/app",
        name: "app信息",
        auth: true,
        type: 'admin',
        component: AppList
    },
    {
        path: "/console/proxy/app",
        name: "应用信息管理",
        type: 'proxy',
        auth: true,
        component: ProxyAppList
    },
    {
        path: "/console/proxy/user",
        name: "账户信息查看",
        auth: true,
        type: 'proxy',
        component: ProxyUserList
    },
    {
        path: "/console/proxy/order",
        name: "订单信息查看",
        auth: true,
        type: 'proxy',
        component: ProxyOrderList
    },
]
