import request from 'fetch/requset';
import { objToStr } from 'utils/format';
// ***********************************************************************账户
//账户登陆
export function userLogin(params: any): any {
    return request('mt/ac/ac1', {
        method: 'POST',
        data: params,
    });
}

// 账号注册
export function userRegister(params: any): any {
    return request('mt/ac/ac2', {
        method: 'POST',
        data: params,
    });
}

//修改账户信息
export function userUpdate(params: any): any {
    return request('mt/ac/ac3', {
        method: 'POST',
        data: params,
    });
}

//重置账号密码
export function userReset(params: any): any {
    return request('mt/ac/ac4', {
        method: 'POST',
        data: params,
    });
}

//获取账户信息
export function userGet(): any {
    return request('mt/ac/ac5', {
        method: 'POST',
    });
}

//分页账户信息
export function userGetPage(params: any): any {
    return request('mt/ac/ac6' + objToStr(params), {
        method: 'GET',
    });
}

//查询应用信息
export function userGetAppPage(params: any): any {
    return request('mt/az/az6', {
        method: 'POST',
        data: params,
    });
}

//删除账户
export function userDel(params: any): any {
    return request('mt/b/b4', {
        method: 'POST',
        data: params,
    });
}

// ***********************************************************************app
//查询应用信息
export function appGet(params: any): any {
    return request('mt/az/az1', {
        method: 'POST',
        data: params,
    });
}

// 新增应用信息
export function appAdd(params: any): any {
    return request('mt/az/az2', {
        method: 'POST',
        data: params,
    });
}

// 删除应用信息
export function appDel(params: any): any {
    return request('mt/az/az3', {
        method: 'POST',
        data: params,
    });
}

//修改应用信息
export function appUpdate(params: any): any {
    return request('mt/az/az4', {
        method: 'POST',
        data: params,
    });
}

// ***********************************************************************收款
//查询收款账号
export function getPayType(params: any): any {
    return request('mt/b/b3', {
        method: 'POST',
        data: params,
    });
}

//新增收款账号
export function payAdd(params: any): any {
    return request('mt/b/b1', {
        method: 'POST',
        data: params,
    });
}

//修改收款账号
export function payUpdate(params: any): any {
    return request('mt/b/b2', {
        method: 'POST',
        data: params,
    });
}

// ***********************************************************************订单
// 提交订单信息
export function orderPost(params: any): any {
    return request('mt/o/o1', {
        method: 'POST',
        data: params,
    });
}

// 审核订单信息
export function orderCheck(params: any): any {
    return request('mt/o/o2', {
        method: 'POST',
        data: params,
    });
}

// 开通测试
export function openTest(params: any): any {
    return request('mt/az/az5', {
        method: 'POST',
        data: params,
    });
}


//分页查询订单信息
export function getOrderPage(params: any): any {
    return request('mt/o/o3' + objToStr(params), {
        method: 'GET',
    });
}


// ***********************************************************************

// 查询产品信息
export function getProduct(params: any): any {
    return request('mt/p/p1', {
        method: 'POST',
        data: params,
    });
}

//APP续费服务
export function postRecharge(params: any): any {
    return request('mt/p/p2', {
        method: 'POST',
        data: params,
    });
}

//分页查询帐变信息
export function getFundChange(params: any): any {
    return request('mt/ac/ac7' + objToStr(params), {
        method: 'GET',
    });
}

//获取联系信息
export function getContact(): any {
    return request('mt/s/s1', {
        method: 'POST',
    });
}


//获取联系信息
export function getDownloadUrl(): any {
    return request('mt/s/s2', {
        method: 'POST',
    });
}












