import { observable, action } from 'mobx';
import md5 from 'md5'
import { userRegister, userLogin, getContact, userGet ,getDownloadUrl} from 'api/api'
import { setLoginInfo, getLoginInfo, setUserToken } from 'fetch/auth'
import { message } from 'antd';


class User {
    @observable isLogined = false; // 登录
    @observable userInfo: any; // 用户信息
    @observable History: any; // 用户信息
    @observable contactInfo: any //联系人
    @observable downloadUrlInfo: any //下载链接
    constructor() {
        this.checkUserLogin();
    }

    //登陆
    @action onUserLogin = async (values: any) => {
        values.password = md5(values.password)
        let res = await userLogin(values)
        if (res.ret === 'C2') {
            this.isLogined = true
            this.userInfo = { ...res.data.acct, isLogined: true }
            setLoginInfo(this.userInfo)
            setUserToken(res.data.token)
            message.success('登录成功')
            let path = res.data.acct.accountType == 0 ? `/console/admin/order` : `/console/proxy/app`
            this.History.push(path)
        }
    };


    // 注册
    @action onRegister = async (values: any) => {
        let res = await userRegister({ ...values, password: md5(values.password) })
        if (res.ret === 'C2') {
            this.onUserLogin(values)
            message.success('注册成功')
        }
    };

    //检测是否已经登录
    @action checkUserLogin = async () => {
        let loginInfo = getLoginInfo()
        this.isLogined = loginInfo ? loginInfo.isLogined : false
        this.userInfo = loginInfo
    }

    //获取getHistory
    @action getHistory = async (history) => {
        this.History = history
    }

    //获取联系人
    @action getContactInfo = async () => {
        let res = await getContact()
        if (res.ret === 'C2') {
            this.contactInfo = res.data
        }
    }

    //获取下载链接
    @action getDownloadUrlInfo = async () => {
        let res = await getDownloadUrl()
        if (res.ret === 'C2') {
            this.downloadUrlInfo = res.data
        }
    }

    //退出
    @action onCheckOut = async () => {
        this.isLogined = false
        this.userInfo = null
        setLoginInfo(null)
        setUserToken('')
        this.History.push('/')
    }

    //刷新余额
    @action onUserGet = async () => {
        let res = await userGet()
        if (res.ret === 'C2') {
            this.userInfo = { ...res.data, isLogined: true }
            setLoginInfo(this.userInfo)
        }
    }
}


export default new User();